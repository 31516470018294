<template>
  <v-container fluid>
    <h3 class="page-title">店員兌換記錄</h3>
    <v-row>
      <v-col cols="6">
        <DateRangePicker v-model="searchQuery.date" :max="expiredDate" />
      </v-col>
      <v-col>
        <v-btn color="primary" class="" @click="search">查詢</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <h4>篩選條件</h4>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="4">
        <v-select
          label="帳號"
          v-model.trim="filterQuery.user"
          :items="users"
          outlined
          dense
          hide-details
          clearable
        >
        </v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          label="關鍵字"
          v-model.trim="filterQuery.keyword"
          outlined
          dense
          hide-details
          clearable
        >
        </v-text-field>
      </v-col>
      <!-- <v-col cols="auto">
        <v-checkbox
          class="mt-0 pt-0"
          label="只顯示超過 3 次"
          v-model="filterQuery.warning"
          hide-details
        ></v-checkbox>
      </v-col> -->
      <v-col cols="auto" class="ml-auto font-weight-bold">
        <div>兌換成點數: {{ pointsSum.income }}</div>
        <div>兌換成商品: {{ pointsSum.outcome }}</div>
      </v-col>
    </v-row>
    <v-row v-if="result">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="filterResult"
          :mobile-breakpoint="0"
          item-key="index"
          show-expand
          :footer-props="{
            itemsPerPageText: '每頁幾筆',
            itemsPerPageOptions: [10, 30, -1],
          }"
          group-by="userID"
        >
          <template v-slot:item.type="{ item }">
            {{ item.point > 0 ? "兌換點數" : "兌換商品" }}
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="pa-4">
              <pre>{{ pretty(item.content) }}</pre>
            </td>
          </template>
          <template v-slot:group.header="{ group, headers, items }">
            <td :colspan="headers.length" class="">
              <div class="pa-2 font-weight-bold">
                {{ `${group}(客人ID): ${items.length} 次` }}
              </div>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import DateRangePicker from "@/components/share/DateRangePicker.vue";

export default {
  name: "ClawSystemLog",
  components: { DateRangePicker },
  data: () => ({
    searchQuery: {
      date: [],
    },
    filterQuery: {
      user: null,
      keyword: "",
    },
    result: null,
    users: [],
    branches: [],
    headers: [
      // { text: "序號", value: "index" },
      { text: "時間", value: "time" },
      { text: "類型", value: "type", sortable: false },
      { text: "點數", value: "point", sortable: false },
      // { text: "客人", value: "userID" },
      { text: "觸發人", value: "from", sortable: false },
    ],
  }),
  created() {},
  computed: {
    expiredDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    filterResult() {
      let list = this.result;
      const { user, keyword } = this.filterQuery;

      if (user) {
        list = list.filter((row) => row.from === user);
      }
      if (keyword) {
        list = list.filter(
          (row) =>
            row.action.includes(keyword) ||
            row.from.includes(keyword) ||
            row.content.includes(keyword)
        );
      }
      return list;
    },
    pointsSum() {
      if (!this.filterResult) return { income: 0, outcome: 0 };

      let income = 0;
      let outcome = 0;
      this.filterResult.forEach(({ point }) => {
        if (!point) return;
        if (point > 0) {
          income += point;
          return;
        }
        outcome += point;
      });
      return { income, outcome };
      // return _.sumBy(this.filterResult, "point");
    },
  },
  methods: {
    pretty: (val, indent = 2) => {
      if (typeof val !== "object") {
        try {
          val = JSON.parse(val);
        } catch (err) {
          return val;
        }

        return JSON.stringify(val, null, indent);
      }
    },
    async search() {
      if (this.searchQuery.date.length !== 2) {
        this.$toast.warning("請輸入日期區間");
        return false;
      }
      this.$vloading.show();

      const { data } = await this.axios.get(`/dashboard/log`, {
        params: { dateRange: this.searchQuery.date, action: "現場兌換" },
      });
      const result = data
        .filter((o) => !["成功", "台東"].includes(o.content.storeID))
        .map((o) => {
          const branch = o.content.branch || o.content.storeID;
          return {
            ...o,
            branch,
            userID: o.content.userID,
            point: o.content.point,
          };
        })
        .map((o) => {
          o.content =
            typeof o.content == "object"
              ? JSON.stringify(o.content)
              : o.content;
          return o;
        });
      this.result = result.reverse();

      this.users = Object.keys(_.groupBy(result, "from"));
      this.branches = Object.keys(_.groupBy(result, "branch"));

      this.users.unshift({ text: "全選", value: "" });
      this.branches.unshift({ text: "全選", value: "" });

      this.$vloading.hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
