<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        v-bind="attrs"
        v-on="on"
        :label="label || '日期區間'"
        outlined
        readonly
        dense
        hide-details
        clearable
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      range
      no-title
      scrollable
      locale="zh-tw"
      :max="max"
      :min="min"
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> 取消 </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)"> 確定 </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "DateRangePicker",
  props: ["value", "max", "min", "label"],
  data: () => ({
    menu: false,
  }),
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    formattedDate() {
      const date = this.value;
      return date[0] && date[1]
        ? dayjs(date[0]).format("YYYY-MM-DD") +
            " ~ " +
            dayjs(date[1]).format("YYYY-MM-DD")
        : "";
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped lang="scss"></style>
